import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import {CallService} from '../../services/call.service';
import { Inject } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {NotificationService} from '../../services/notification.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { HttpParams} from '@angular/common/http';
import { DomSanitizer} from '@angular/platform-browser';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import {TdcPopupComponent} from '../popups/tdc-popup/tdc-popup.component';
import { AddressPopupComponent } from '../popups/address-popup/address-popup.component';
import {SpinnerService} from '../../services/spinner.service';
import { TermsPopupComponent } from './terms-popup/terms-popup.component';
import { findPhoneNumbersInText } from 'libphonenumber-js';
import {FacPopupComponent} from './fac-popup/fac-popup.component';


@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {
 
  public plan : any;
  public title : any;
  public option : any;
  public checkoutOption : any;
  id_adress: any = null;
  httpOptions : any;
  id_tdc:any = null;
  status_tdc : any;
  tarjetas: any = [];
  id_tdc_corer : any;
  tdc:any;
  id_3dshasplan: any;
  provinces: any[]= [] ;
  corregimientos: any[]= [] ;
  form: FormGroup ;
  customer_email:any;
  id_hasplan:any;
  detail:any;
  options:any;
  terms : any;
  phone : any;
  show : any = false;
  configuration: any  = {initialCountry: "PA",separateDialCode:true} ;
  // configuration  = {} ;

  errorNumber = true;
  numberRequired = false;
  public intlTelInput : any;

 constructor(private spinner : SpinnerService,private sanitizer: DomSanitizer,private dialog: MatDialog,@Inject(DOCUMENT) private document: Document,private call : CallService,public auth : AuthService, private notify : NotificationService,private router : Router, private activateRoute : ActivatedRoute) { 

    this.form = new FormGroup({
      adress: new FormControl('', Validators.required),
      number: new FormControl('', Validators.required),
      name_tdc : new FormControl('', Validators.required),
      number_tdc: new FormControl('', Validators.required),
      month: new FormControl('', Validators.required),
      year: new FormControl('', Validators.required),
      ccv: new FormControl('', Validators.required),
      province : new FormControl('', Validators.required),
      corregimiento : new FormControl('', Validators.required)
    });

    
  }

  ngOnInit(): void {

    this.spinner.open();
    this.options = this.configuration;
  
    if(!this.auth.isLoggedIn()){
      this.spinner.close();
      this.show = true;
    }
  
    this.activateRoute.queryParams.subscribe(params =>{
      this.checkoutOption = Number(params.option);
      switch(this.checkoutOption){
        case 1: this.option = "White & Pink";
        break;
        case 2 : this.option = "Only Reds";
        break;
        case 3: this.option = "Mix";
        break;
        case 4 : this.option = "Bi-Mensual";
        break
        default: this.option = "Anual";
      }
      this.call.get(this.call.HOST + "/ecommerce/plan/" + params.plan).subscribe(plan =>{
    
        this.plan = params.plan;
        this.title = plan.result.title;
        this.detail = plan.result.detail;
        this.terms = plan.result.terms;
        

        this.call.get(this.call.HOST + "/partner/" + this.auth.getPartner()).subscribe(response =>{

          //console.log(response.result)
          
          this.provinces = response.result.provinces;
          this.provinces = this.provinces.sort((a,b) => a.name > b.name ? 1 : -1);// ORDENAMOS ALFABETICAMENTE

          this.spinner.close();
        }, err =>{
          this.spinner.close();
          this.notify.showError(err);
          this.notify.showError(err)
        });

      }, err =>{
        this.spinner.close();
        this.notify.showError(err);
        this.spinner.close();
      });
      
    });
    
   

  }

  checkout(){
    const data = this.form.value;
  
    // COMPROBAMOS SI EL USUARIO ESTA LOGEADO
    if(this.auth.isLoggedIn()){ // SI ESTA LOGEUADO ENTONCES ENVIAMOS DIRECTAMENTE LA SUSCRIPCION
      this.sendCheckout();
    }else{ // SI NO LO ESTA ENTONCES COMPROBAMOS EXISTENCIA DEL CORREO

      this.spinner.open();

      this.call.get(this.call.HOST + "/auth/" + data.email).subscribe( r =>{ // SI EXISTE LO MANDAMOS A INICIAR SESION

        this.spinner.close();
        this.notify.showInfo("Ya posees una cuenta registrada, por favor ingresa al sistema");
        
        // @ts-ignore: Object is possibly 'null'.
        this.document.getElementById('id01')?.style.display='block'; //MOSTRAMOS LA PANTALLA DE LOGEO DE LA APLICACION

      }, err =>{ // SI NO EXISTE PROCEDEMOS A REGISTRARLO

        this.sendSingUp();
        
      })

    }
  }

  // FUNCION PARA REGISTRAR LA SUSCRIPCION
  sendCheckout(){
    // TOMAMOS LA DATA DEL REGISTRO
    this.spinner.open();

    const data = this.form.value;

    // ESTE ES EL PROECSO PARA REALIZAR LA SUSCRIPCION
    let tdc : any = {};
    let adress : any = {};
    let send : any = {};

    // EN CASO DE HABER SELECCIONADO UNA DE LAS TARJETAS REGISTRADAS
         
    if(this.id_tdc != null ){
      tdc.id = this.id_tdc;
      tdc.status = this.status_tdc;
      tdc.idcorer = this.id_tdc_corer;
      tdc.ccv = data.ccv;
    
      }else{  

      // HACEMOS ESTO PARA VALIDAR QUE NO ESTE INGRESANDO UNA TARJETA YA REGISTRADA
      let found = false;
      for(let tdc of this.tarjetas){
        if(tdc.substr(tdc.length-4) ==  data.number_tdc.toString().substr(data.number_tdc.toString().length-4) ){
          found = true;
        }
      }
   
      // EN CASO DE NO EXISTIR LA TARJETA
      if(!found){
      tdc.numberCreditCard = data.number_tdc;
      tdc.nameHolderCreditCard = data.name_tdc;
      tdc.expMonthCreditCard = data.month;
      tdc.expYearCreditCard = data.year;
      tdc.ccv = data.ccv;
      }else{  // EN CASO DE HABERLA ENCONTRADO
        this.spinner.close();
        this.notify.showError('La tarjeta ya se encuentra registrada');
        //this.spinner.hide();
        return;
      }

    }

    this.tdc = 'XXXXXXXXXXX' + data.number_tdc.substring(data.number_tdc.length - 4);

    // EN CASO DE HABER SELECCIONADO UNA DE LAS DIRECCIONES REGISTRADAS
    if(this.id_adress != null){
      adress.id = this.id_adress;
    }else{
        // en caso de no existir la direccion entonces
        //adress.name = data.name;
        adress.adress = data.adress;
        //adress.province = 8;
        adress.province = +data.province;
        adress.corregimiento = +data.corregimiento;
    }

    /*if(this.checkoutOption == 4 || this.checkoutOption == 5){
      this.checkoutOption = 1;
    }*/


    send = {creditcard:tdc, adress:adress, customer:this.auth.getCustomer(), option:1 , scope : this.auth.getScope(), phone : data.number, plan : this.plan, service: this.auth.getService(), param : 5};
    
  //  console.log(send);

    this.call.send(send,this.call.HOST + "/suscription").subscribe(response=>{

      // SUSCRIPCION PROCESADA CORRECAMENTE

      this.spinner.close();

      //console.log(response);

     if(response.htmlFormData){ // EN CASO DE QUE SEA 3DS
      
      this.id_3dshasplan = response.id;
       //this.responseForm = response.htmlFormData; 

       let dialogRef: MatDialogRef<any> = this.dialog.open(FacPopupComponent, {
        width: '50%',
        height: '60%',
        disableClose: true,
        data: { form: this.sanitizer.bypassSecurityTrustHtml(response.htmlFormData), id: response.id }
      })
      dialogRef.afterClosed()
        .subscribe(res => {
          this.myLoadEvent();
        })
      
     }else{ 
      window.location.href = "/confirmation?email="+this.form.value.email+"&confirmation=" + response.result;
      this.spinner.close();
      // ENVIAMOS EVENTO DE COMPRA A GOOGLE ANALYTICS SOLO SI ESTAMOS EN PRODUCCION
      /*if(environment.production){
        let id_plan : any = localStorage.getItem("plan");
        this
        .googleAnalyticsService
        .eventEmitterCheckout("purchase", "ecommerce", "click", "Suscribirse", id_plan,this.option,this.customer);
      }*/
      
     }
    }, (err) =>{
      // OCURRIO ERROR CON LA SUSCRIPCION
      console.log(err);

      this.notify.showError(err);
      this.spinner.close();
      
    }) ; 



  }

  // FUNCION PARA REGISTRAR EL CLIENTE EN LA PLATAFORMA
  sendSingUp(){

    this.spinner.open();

    let signupData : any;

    signupData.name_customer = "XXXXXXXXX";
    signupData.lastName_customer = "XXXXXXXXXX";
    signupData.identifier_customer = this.form.value.email  ;
    signupData.email_customer = this.form.value.email  ;
    // COLOCAMOS ESTATUS E ACTIVO AL CLIENTE
    signupData.status_customer = 1;
    // COMO ES CLIENTE DE LANDING, SU EMAIL SERA EL CODIGO DEL CLIENTE
    signupData.code_customer = this.form.value.email;

    // BUSCAMOS LA INFORMACION DEL SERVICIO
    this.call.get(this.call.HOST + "/ecommerce/service/" + this.auth.getService()).subscribe( response =>{

      // ESTE ES EL PARTNER QUE ES DUE*O DEL PLAN Y SERVICIO
      signupData.partner = {id:response.result.partner_id};
      signupData.service = Number(this.auth.getService());

      this.call.post(signupData, this.call.HOST + "/ecommerce/customer/registry/fmotta").subscribe( result =>{
        this.spinner.close();
        // MANDAMOS A INICIAR LA SESION DEL USUARIO
        this.login(result.accessToken,result.customer.email_customer)

      }, err =>{
        console.log(err);
        this.spinner.close();
      })

    }, err =>{
      this.spinner.close();
      this.notify.showError(err);
    })


  }

  onProvinceChange(province:any){

    this.corregimientos = [];
    this.corregimientos = this.provinces[this.provinces.findIndex(t => t.id == province)].corregimientos;
    this.corregimientos = this.corregimientos.sort((a,b) => a.name > b.name ? 1 : -1); // ORDENAMOS ALFABETICAMENTE

  }

  onCorregimientoChange(){

    if(this.id_adress != null){
      delete this.id_adress;
    }

  }

  onProvinceChargeChange(province:any){

    if(this.id_adress != null){
      this.id_adress == null;
    }

    this.corregimientos = [];
    this.corregimientos = this.provinces[this.provinces.findIndex(t => t.id == province)].corregimientos;
 
  }

  onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }


  // FUNCION PARA LOGUEAR AUTOMATICAMENTE AL CLIENTE EN LA APLICACION
  login(username : string, password : string) {

    this.spinner.open();

    let params = new HttpParams() ;
   
		params = params.set('grant_type', 'password');
		params = params.set('username', username);
		params = params.set('password', password);
		params = params.set('client_id', 'web_site');

    // SOLICITAMOS TOKEN
    this.call.auth(this.call.HOST + "/oauth/token",params,this.httpOptions).subscribe( res =>{

      localStorage.setItem('token', res.access_token);
      // SOLICITAMOS VALIDACION DEL USER PARA LOGIN
      this.call.get(this.call.HOST + '/user/ecommerce/' + res.user_id).subscribe(user =>{
        this.spinner.close();
         // GUARDAMOS EL ID DEL USER
         localStorage.setItem('user', user.result.user.id);
         localStorage.setItem('customer', user.result.customer.id);
         let time = new Date(); // MOMENTO DEL LOGIN
         let expDate = new Date(time.setMinutes(time.getMinutes() + this.auth.APP_TIME_SESSION))
         localStorage.setItem(this.auth.APP_USER_TIME, expDate.toString());
        // PASO FINAL - EL CHECKOUT
         this.sendCheckout(); // AHORA SI PROCEDEMOS A REALIZAR EL CHECKOUT

      }, err =>{
        console.log(err);
        this.spinner.close();
        this.notify.showError(err);
      })

    }, err =>{
      console.log(err);
      this.spinner.close();
      this.notify.showError(err);
    })

     
  }


  myLoadEvent(){

    this.spinner.open();

    this.call.get(this.call.HOST + "/hasplan/" + this.id_3dshasplan).subscribe(chp =>{

      if(chp.result.status_customerPlan  != 4){
         if(chp.result.status_customerPlan == 1 ){ // ESTO ES QUE SE COBRO Y TODO BIEN CON LA SUSCRIPCION ESTA ACTIVA
          // this.id_hasplan = this.id_3dshasplan;
           //this.customer_email = chp.customer.email_customer;
           // ENVIAMOS EVENTO DE COMPRA A GOOGLE ANALYTICS SOLO SI ESTAMOS EN PRODUCCION
           /*if(environment.production){
             let id_plan : any = localStorage.getItem("plan");
             this
             .googleAnalyticsService
             .eventEmitterCheckout("purchase", "ecommerce", "click", "Suscribirse", id_plan,this.option,chp.customer.id);
           }*/
           this.spinner.close();
           window.location.href = "/confirmation?email="+chp.result.customer.email_customer+"&confirmation=" + this.id_3dshasplan;
           

       }else{
        this.spinner.close();
        this.notify.showError("Su pago fue rechazado por el banco");
 
       }
       
      }
      
    })
   }


   tdcPopUp(data: any = {}) {
    
    let dialogRef: MatDialogRef<any> = this.dialog.open(TdcPopupComponent, {
      width: '50%',
      height : '60%',
      disableClose: true,
      data: { }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }
        //console.log(res);
        this.id_tdc = res.token;
        this.status_tdc = res.status;
        this.id_tdc_corer = res.id;
        this.form.controls["name_tdc"].setValue(res.nameHolderCreditCard);
        this.form.controls["number_tdc"].setValue(res.maskCreditCard);
        this.form.controls["month"].setValue(res.yearMonthExpiration.substr(res.yearMonthExpiration.length-2));
        this.form.controls["year"].setValue(res.expYearCreditCard.substr(res.expYearCreditCard.length-2));
       
      })
  }

  addressesPopUp(data: any = {}) {
  
    let dialogRef: MatDialogRef<any> = this.dialog.open(AddressPopupComponent, {
      width: '50%',
      height : '60%',
      disableClose: true,
      data: { }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }
        // TOMAMOS LA INFORMACION DE LA DIRECCION SELECCIONADA
        
        if(this.provinces[this.provinces.findIndex(t => t.id == res.province.id)] != null){
          this.id_adress = res.id;
          this.form.controls["adress"].setValue(res.adress);
          this.form.controls["province"].setValue(res.province.id);
          this.form.controls["corregimiento"].setValue(res.corregimiento.id);
        }
      
        //this.form.controls["corregimiento"].setValue(res.corregimiento.id);
        
        this.form.controls["number"].setValue(res.phone);
        if( res.phone !== null){
          let findPhone = findPhoneNumbersInText(res.phone);
          if(findPhone.length > 0){
            this.phone = findPhone[0].number.nationalNumber;
            this.configuration  = {initialCountry: findPhone[0].number.country ,separateDialCode:true} ;
            this.intlTelInput.setCountry(this.configuration.initialCountry);
            this.options = this.configuration;
          }
        }
        this.onProvinceChargeChange(res.province.id);  
      })
  }

  changeTDC(){
    // Borramos la TDC  que haya seleccionado previamente
    delete this.id_tdc;
    delete this.status_tdc;
  }

  termsPopUp(data: any = {}) {
    
    let dialogRef: MatDialogRef<any> = this.dialog.open(TermsPopupComponent, {
      width: '100%',
      height: '60%',
      disableClose: true,
      data: { terms: this.terms }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }
      })
  }

  hasError(obj:any){
    // FALSE : ERROR - TRUE -> NO ERROR
    //console.log(obj);
    this.errorNumber = obj;
    if(obj){
      this.numberRequired = true;
      console.log(this.numberRequired);
    }
  }

  onCountryChange(obj:any){
    // console.log("onCountryChange",obj);
   
  }
 
  telInputObject(obj:any){
    // console.log("telInputObject",obj);
    this.intlTelInput  = obj;
  }

  getNumber(obj:any){
    this.form.controls["number"].setValue(obj);
  }

}
